<script>
export default {
    inheritAttrs: false,
};
</script>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import BnContactEditDialog from '@/components/contacts/BnContactEditDialog.vue';
import { getRelationshipTypes, getRelationshipTypesInverse, findOrganizationRelationshipList } from '@/common/api/terminology.api';
import { EMERGENCY_CONTACT, NEXT_OF_KIN, LEGAL_GUARDIAN } from '@/common/config';
import BnContactSearch from '@/components/BnContactSearch.vue';
import router from '@/router';
import BnInfo from '@/components/BnInfo.vue';
import BnAvatar from '@/components/BnAvatar.vue';
import DataProvider from '@/components/DataProvider.js';
import { Reference } from '@/fhirworks';
import { useAuthStore } from '@/stores/auth';
import { useContactFlow } from '@/common/workflowEngine/useContactFlow';
import { addRefToBackBtnListiner, removeRefToBackBtnListiner } from '@/common/nativeBackButton';
import { useGetDisplayResource } from '@/composables/useGetDisplayResource';

const { getDisplay, getReference } = useGetDisplayResource();

const authStore = useAuthStore();

const emit = defineEmits(['addNewContactWithRelationshipCompleted', 'dataChanged', 'refreshGuarantor']);
const props = defineProps({
    subjectId: {
        type: String,
    },
    subjectResourceType: {
        type: String,
    },
    subject: {
        type: Object,
    },
    relatedId: {
        type: String,
    },
    related: {
        type: Object,
    },
    relationships: {
        type: Array,
    },
    addingNewContact: {
        type: Boolean,
        default: false,
    },
    newContactData: {
        type: Object,
    },
    isWidget: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
    isEmergencyContact: {
        type: Boolean,
        default: false,
    },
    isLegalGuardian: {
        type: Boolean,
        default: false,
    },
    existingRelationships: {
        type: Array,
        default: () => [],
    },
});

const workingProps = ref({
    primary: undefined,
    inverse: undefined,
    twoWay: true,
    subject: undefined,
    related: undefined,
    relationship: undefined,
    inverseRelationship: undefined,
    emergencyContact: props.isEmergencyContact,
    nextOfKin: undefined,
    legalGuardian: props.isLegalGuardian,
});

const dpRelationship = ref([]);
const relationshipOptions = ref([]);
const relationshipInverse = ref([]);
const emergencyContactLabel = EMERGENCY_CONTACT;
const nextOfKinLabel = NEXT_OF_KIN;
const legalGuardianLabel = LEGAL_GUARDIAN;
const searchError = ref(null);

const subjectDisplay = ref('');
const relatedDisplay = ref('');
const newContactDisplay = ref('');

const subject = ref(props.subject);
const related = ref(props.related);

onMounted(() => {
    getRelationshipTypes().then((relationshipTypes) => {
        if (relationshipTypes.length) {
            relationshipOptions.value = relationshipTypes
                ?.sort((a, b) => {
                    if (a.resource.display > b.resource.display) return 1;
                    return -1;
                })
                .map((e) => ({ display: e.resource.display, system: e.resource.system, code: e.resource.code }));
            relationshipOptions.value = relationshipOptions.value.filter((item) => !['C', 'N', 'G'].includes(item.code));
        }
    });
    getRelationshipTypesInverse().then((relationshipTypes) => {
        relationshipInverse.value = relationshipTypes;
    });
});

watch(
    () => workingProps.value.subject,
    async (newVal) => {
        let label = dpRelationship.value.find((item) => item.resourceType === newVal.resourceType && item.id === newVal.id);
        subjectDisplay.value = await getDisplay(label || newVal);
    },
);

watch(
    () => workingProps.value.related,
    async (newVal) => {
        let label = dpRelationship.value.find((item) => item.resourceType === newVal.resourceType && item.id === newVal.id);
        relatedDisplay.value = await getDisplay(label || newVal);
    },
);

watch(
    () => props.newContactData,
    async (newVal) => {
        let label = dpRelationship.value.find((item) => item.resourceType === newVal.resourceType && item.id === newVal.id);
        newContactDisplay.value = await getDisplay(label || newVal);
    },
    { deep: true, immediate: true },
);

const dpRelationshipQuery = computed(() => {
    if (subject.value && related.value) {
        return { resource: [...props.relationships.filter((it) => it), subject.value, related.value] };
    }

    if (props.subjectId && props.relatedId) {
        return {
            query: [
                { query: '_include=subject&subject=' + props.subjectId + '&related=' + props.relatedId, resourceType: 'BN_Relationship' },
                { query: '_include=subject&subject=' + props.relatedId + '&related=' + props.subjectId, resourceType: 'BN_Relationship' },
            ],
        };
    }

    workingProps.value.subject = { id: props.subjectId, resourceType: props.subjectResourceType };
    if (props.addingNewContact) {
        workingProps.value.related = new Reference({ id: undefined, resourceType: 'Patient', display: 'New patient' });
        workingProps.value.newRelationship = true;
    }
    return [];
});
const primaryRelationshipLabel = computed(() => {
    const contact = subjectDisplay.value;
    const related = props.addingNewContact ? newContactDisplay.value : relatedDisplay.value;
    return related + ' is ' + contact + "'s";
});
const inverseRelationshipLabel = computed(() => {
    const contact = subjectDisplay.value;
    const related = props.addingNewContact ? newContactDisplay.value : relatedDisplay.value;
    return contact + ' is ' + related + "'s";
});
const relatedReference = computed(() => {
    return workingProps.value.related;
});
const isSeveredRelationship = computed(() => {
    return !(workingProps.value.newRelationship || props.addingNewContact) && !workingProps.value?.primary?.active;
});
const mainPatient = computed(() => {
    return dpRelationship?.value.find((el) => el.resourceType === 'Patient' && el.id === props.subjectId);
});
const loadRelationship = function () {
    const primary = dpRelationship.value.find((item) => item.resourceType === 'BN_Relationship' && item.subject.id === props.subjectId) || undefined;
    if (!primary?.id) return;

    const inverse = dpRelationship.value.find((item) => item.resourceType === 'BN_Relationship' && item.subject.id === props.relatedId) || undefined;

    workingProps.value = {
        primary: primary,
        inverse: inverse,
        twoWay: !!inverse?.id,
        subject: primary.subject,
        related: primary.related,
        relationship: primary.relationship?.find((item) => item.code && !['C', 'N', 'G'].includes(item.code)),
        inverseRelationship: inverse?.relationship?.find((item) => item.code && !['C', 'N', 'G'].includes(item.code)),
        emergencyContact: primary.relationship?.some((a) => a.code === 'C'),
        nextOfKin: primary.relationship?.some((a) => a.code === 'N'),
        legalGuardian: primary.relationship?.some((a) => a.code === 'G'),
    };
};
// used in the contact edit dialog
const saveNewContactRelationship = async function (contact) {
    workingProps.value.related.id = contact.id;
    workingProps.value.related.display = contact.fullName;
    workingProps.value.related.resourceType = contact.resourceType;
    return await saveRelationship();
};
const saveRelationship = async function () {
    const { saveRelationship: saveRelationshipFlow } = useContactFlow();
    emit('dataChanged', workingProps.value);
    emit('refreshGuarantor', true);
    return await saveRelationshipFlow(workingProps.value);
};
const openContact = function (item) {
    router.push({ name: item.resourceType.toLowerCase(), params: { id: item.id, activeTab: 'contact' } });
};
const contactEditDialog = ref(null);
const contactSearch = ref(null);
const dpRelationshipRef = ref(null);
const bnDialog = ref(null);
const addContact = function (item) {
    const options = {
        header: 'Add contact',
        persistent: true,
    };
    const localProps = {
        add: item,
        addNewRelatedContact: true,
        relationSubject: workingProps.value.subject,
        isEmergencyContact: props.isEmergencyContact,
    };
    addRefToBackBtnListiner(contactEditDialog.value, 'close', 'contactEditDialog');
    contactEditDialog.value.show(options, localProps).then(async (resource) => {
        removeRefToBackBtnListiner('contactEditDialog');
        if (resource) {
            emit('addNewContactWithRelationshipCompleted', resource);
            dpRelationshipRef.value?.refresh();
        }
    });
};
const selectContact = async function (contact) {
    // disallow self-relationships
    if (contact.id === workingProps.value.subject?.id) {
        searchError.value = 'Sorry, you cannot self-relate a contact!';
        window.setTimeout(() => {
            contactSearch.value.focus();
            searchError.value = null;
        }, 3000);
        return;
    }
    // set contact resources
    dpRelationshipRef?.value.addItem(contact);

    if (props.isEmergencyContact) {
        workingProps.value.emergencyContact = true;
    }

    if (props.isLegalGuardian) {
        workingProps.value.legalGuardian = true;
    }

    // rebuild workingProps
    workingProps.value = {
        ...workingProps.value,
        related: { ...(await getReference(contact, true)), photo: contact.photo },
        twoWay: true,
        newRelationship: true,
    };

    dpRelationshipRef.value?.refresh();
};
// used in the contact edit dialog
const deleteRelationship = async function () {
    // let sameGuarantor = false;
    const options = {
        header: 'Delete Relationship',
        warning: workingProps.value.twoWay ? 'Both sides of this two-way relationship will be deleted!' : undefined,
        message: 'Are you sure you want to delete this relationship?',
        confirm: 'Yes',
        cancel: 'No',
    };

    let message = 'Guarantor will be reverted back to Self from ' + workingProps?.value.related.display + '.';
    if (mainPatient?.value?.Guarantor?.id === workingProps?.value.related.id) {
        if (options.warning === undefined) {
            options.warning = message;
        } else {
            options.warning = [options.warning];
            options.warning.push(message);
        }
        // sameGuarantor = true;
    }

    return bnDialog.value.show(options).then(async (confirm) => {
        if (!confirm) return;
        const { deleteRelationship } = useContactFlow();
        emit('refreshGuarantor', true);
        return await deleteRelationship(workingProps.value);
    });
};
// used in the contact edit dialog
const unseverRelationship = async function () {
    const options = {
        header: 'Unsever relationship',
        warning: workingProps.value.twoWay ? 'Both sides of this two-way relationship will be unsevered!' : undefined,
        message: 'Are you sure you want to unsever this relationship?',
        confirm: 'Yes',
        cancel: 'No',
    };
    return bnDialog.value.show(options).then(async (confirm) => {
        if (!confirm) return;
        const { unseverRelationship } = useContactFlow();
        emit('dataChanged');
        emit('refreshGuarantor', true);
        return await unseverRelationship(workingProps.value);
    });
};
// used in the contact edit dialog
const severRelationship = async function () {
    let sameGuarantor = false;
    let severWarnings = [];
    if (workingProps.value.twoWay) {
        severWarnings.push('Both sides of this two-way relationship will be severed!');
    }
    if (workingProps.value.emergencyContact) {
        severWarnings.push('The emergency contact setting will be cleared.');
    }
    if (workingProps.value.nextOfKin) {
        severWarnings.push('The next of kin setting will be cleared.');
    }
    if (workingProps.value.legalGuardian) {
        severWarnings.push('The legal guardian setting will be cleared.');
    }
    if (mainPatient?.value?.Guarantor?.id === workingProps?.value.related.id) {
        severWarnings.push('Guarantor will be reverted back to Self from ' + workingProps?.value.related.display + '.');
        sameGuarantor = true;
    }
    const options = {
        header: 'Sever relationship',
        warning: severWarnings,
        message: 'Are you sure you want to sever this relationship?',
        confirm: 'Yes',
        cancel: 'No',
    };
    return bnDialog.value.show(options).then(async (confirm) => {
        if (!confirm) return;
        if (sameGuarantor) {
            mainPatient.value.Guarantor = {
                display: 'Self',
            };
            await dpRelationshipRef?.value.save(mainPatient.value);
        }
        const { severRelationship } = useContactFlow();
        emit('refreshGuarantor', true);
        return await severRelationship(workingProps.value);
    });
};
const setInverseRelationship = function (relationship) {
    if (!workingProps.value.twoWay || !relationship) return;
    let inverseRelationship = { system: relationship.system };
    const map = relationshipInverse.value.find((item) => item.code === relationship.code);
    if (map?.inverse?.code) {
        inverseRelationship.code = map.inverse.code;
        inverseRelationship.display = map.inverse.display;
        workingProps.value.inverseRelationship = inverseRelationship;
    }
};
const getRelationshipOptions = function (a, b) {
    if (a?.resourceType === 'Organization' || b?.resourceType === 'Organization') {
        return findOrganizationRelationshipList(a.resourceType, b.resourceType);
    } else {
        return relationshipOptions.value;
    }
};
const autoSaveRelationship = async function () {
    if (props.isWidget) {
        await saveRelationship();
    }
};

const refresh = function () {
    emit('dataChanged', workingProps.value);
};

const searchFilter = function (searchItem) {
    let itemExist = props.existingRelationships.find((item) => item.related.id === searchItem.id && item.related.resourceType === searchItem.resourceType);
    return !itemExist;
};

watch(
    () => workingProps,
    () => {
        emit('dataChanged', workingProps.value);
    },
    { immediate: true, deep: true },
);
defineExpose({ saveRelationship, severRelationship, unseverRelationship, saveNewContactRelationship, deleteRelationship });
</script>

<template>
    <data-provider ref="dpRelationshipRef" v-model="dpRelationship" collection v-bind="dpRelationshipQuery" @loaded="loadRelationship" />

    <v-card flat v-bind="$attrs" :class="{ 'scroll-on': !isWidget }" :border="false">
        <div v-if="!workingProps.related && !isWidget">
            <bn-contact-search
                ref="contactSearch"
                :autofocus="true"
                placeholder="Search/add contacts"
                relationship-search
                :filter="searchFilter"
                data-cy="relationshipSearch"
                @add-new-clicked="addContact"
                @item-clicked="selectContact"
            />
            <v-alert v-if="searchError" type="error" text dismissible class="mt-4">{{ searchError }}</v-alert>
        </div>

        <template v-else>
            <template v-if="props.newContactData">
                <div class="bn-section-header">Relationship information</div>
            </template>
            <template v-else>
                <v-list-item class="pa-3 contact-card">
                    <template v-if="!readOnly" #prepend>
                        <bn-avatar class="usprivacy" :resource="relatedReference || workingProps.related" size="46" icon-size="sm" :link="!isWidget" @link="openContact(workingProps.related)" />
                    </template>
                    <v-list-item-title class="usprivacy text-h3">
                        {{ workingProps.related?.display }}
                    </v-list-item-title>
                </v-list-item>
            </template>
            <!-- Primary Relationship -->
            <div class="relationshipDialog" style="max-width: 414px">
                <div data-cy="relationshipName" class="usprivacy font-weight-bold">{{ primaryRelationshipLabel }}</div>

                <v-autocomplete
                    v-model="workingProps.relationship"
                    label="Relationship"
                    :readonly="readOnly"
                    :disabled="isSeveredRelationship"
                    :items="getRelationshipOptions(workingProps.subject, workingProps.related)"
                    item-title="display"
                    item-value="code"
                    return-object
                    :clearable="!readOnly"
                    hide-details
                    data-cy="relationshipSelectBox"
                    class="mt-2 pt-0"
                    @update:model-value="setInverseRelationship"
                    @update:search="autoSaveRelationship"
                >
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title> Relationship not found </v-list-item-title>
                        </v-list-item>
                    </template>
                </v-autocomplete>

                <template v-if="workingProps.subject?.resourceType === 'Patient' && workingProps.related?.resourceType !== 'Organization'">
                    <div class="d-flex flex-column">
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="workingProps.emergencyContact"
                                :readonly="readOnly"
                                :disabled="isSeveredRelationship || isEmergencyContact"
                                data-cy="emergencyContactCheckbox"
                                class="mt-1 align-self-center"
                                hide-details
                                @update:model-value="autoSaveRelationship"
                            >
                                <template #label> <font-awesome-icon class="mr-1" :icon="['far', 'truck-medical']" :disabled="isSeveredRelationship" /> {{ emergencyContactLabel }} </template>
                            </v-checkbox>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="workingProps.legalGuardian"
                                :readonly="readOnly"
                                :disabled="isSeveredRelationship"
                                class="mt-1 align-self-center"
                                hide-details
                                @update:model-value="autoSaveRelationship"
                            >
                                <template #label> <font-awesome-icon class="mr-2" :icon="['far', 'gavel']" />{{ legalGuardianLabel }}</template>
                            </v-checkbox>
                        </div>
                        <div class="d-flex align-center">
                            <v-checkbox
                                v-model="workingProps.nextOfKin"
                                :readonly="readOnly"
                                :disabled="isSeveredRelationship"
                                class="mt-1 align-self-center"
                                hide-details
                                @update:model-value="autoSaveRelationship"
                            >
                                <template #label> <font-awesome-icon class="mr-2" :icon="['fas', 'people-pants-simple']" /> {{ nextOfKinLabel }} </template>
                            </v-checkbox>
                        </div>
                    </div>
                </template>

                <!-- Inverse Relationship -->
                <div v-if="!isWidget" class="d-flex">
                    <v-switch
                        v-model="workingProps.twoWay"
                        :disabled="isSeveredRelationship"
                        label="Two-way relationship"
                        hide-details
                        class="mt-0"
                        @update:model-value="setInverseRelationship(workingProps.relationship)"
                    ></v-switch>
                    <bn-info type="dialog"> In a two-way relationship a related contact will be added to both parties. </bn-info>
                </div>
                <template v-if="workingProps.twoWay && !isWidget">
                    <div class="text-subtitle-1 font-weight-bold mt-2 usprivacy">{{ inverseRelationshipLabel }}</div>
                    <v-select
                        v-model="workingProps.inverseRelationship"
                        :disabled="isSeveredRelationship"
                        label="Relationship"
                        :items="getRelationshipOptions(workingProps.related, workingProps.subject)"
                        item-title="display"
                        item-value="code"
                        return-object
                        clearable
                        class="mt-2 pt-0"
                    ></v-select>
                </template>
            </div>
        </template>
    </v-card>
    <bn-contact-edit-dialog v-if="authStore" ref="contactEditDialog" :auth-store="authStore" @data-changed="refresh" />
    <BnDialog ref="bnDialog" />
</template>
<style>
.contact-card {
    background-color: #f4f4f4;
    border-bottom: 1px #989898 solid;
    margin-bottom: 8px;
    margin-top: 14px;
}
</style>
