<script>
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'BnFormContact',
    components: { BnToolbarBtn },
    props: {
        mode: {
            type: String,
            default: 'edit',
        },
        contact: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            workingContact: null,
        };
    },
    created() {
        this.workingContact = this.contact.getObjectCopy();
    },
    validations() {
        return {
            workingContact: {
                firstName: { required },
                lastName: { required },
                primaryPhoneValue: {
                    required,
                    function(value) {
                        let phoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
                        return phoneRegEx.test(value);
                    },
                },
            },
        };
    },
    methods: {
        cancelContact() {
            this.workingContact = null;
            this.v$.workingContact.$reset();
            this.$emit('cancel');
        },
        async saveContact() {
            this.v$.workingContact.$touch();
            let valid = await this.v$.workingContact.$validate();
            if (!valid) return;

            this.$emit('save', this.workingContact);
        },
    },
};
</script>

<template>
    <v-card flat class="d-flex flex-column">
        <div class="flex-grow-0">
            <ion-header>
                <ion-toolbar>
                    <v-toolbar density="compact" flat>
                        <v-toolbar-title>Contact</v-toolbar-title>

                        <bn-toolbar-btn
                            type="text"
                            :label="mode === 'edit' ? 'Cancel' : 'Discard'"
                            color="secondary"
                            :icon="mode === 'edit' ? 'times' : 'trash'"
                            @click="cancelContact"
                        ></bn-toolbar-btn>
                        <bn-toolbar-btn
                            :disabled="!!v$.workingContact.$errors.length"
                            :label="mode === 'edit' ? 'Update' : 'Add'"
                            color="primary"
                            :icon="mode === 'edit' ? 'check' : 'plus'"
                            data-test="verifyBtn"
                            data-cy="saveContactButton"
                            @click="saveContact"
                        ></bn-toolbar-btn>
                    </v-toolbar>
                </ion-toolbar>
            </ion-header>
            <v-divider></v-divider>
        </div>
        <v-card-text class="mt-4">
            <v-text-field
                id="firstName"
                v-model="workingContact.firstName"
                autofocus
                label="First name"
                v-bind="$bnVuelidateErrorExtractor(v$.workingContact, 'firstName', true)"
                data-cy="firstName"
                @blur="v$.workingContact.firstName.$touch()"
            />
            <v-text-field
                id="lastName"
                v-model="workingContact.lastName"
                label="Last name"
                v-bind="$bnVuelidateErrorExtractor(v$.workingContact, 'lastName', true)"
                data-cy="lastName"
                @blur="v$.workingContact.lastName.$touch()"
            />
            <v-text-field
                id="phoneNumber"
                v-model="workingContact.primaryPhoneValue"
                v-facade="'(###) ###-####'"
                class="mr-2"
                label="Phone"
                type="tel"
                v-bind="$bnVuelidateErrorExtractor(v$.workingContact, 'primaryPhoneValue', true)"
                data-cy="phoneNumber"
                @blur="v$.workingContact.primaryPhoneValue.$touch()"
            ></v-text-field>
        </v-card-text>
    </v-card>
</template>

<style scoped></style>
