<script>
import VueCropper from 'vue-cropperjs';
import BnAvatar from '@/components/BnAvatar.vue';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnImage from '@/components/BnImage.vue';
import fileApi from '@/common/api/file.api';
import image from '@/assets/img/placeholder.png';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';

const sizes = {
    sm: 100,
    md: 250,
    lg: 500,
    xl: 1000,
};

export default {
    name: 'BnImageUploader',
    components: {
        VueCropper,
        BnAvatar,
        BnToolbarBtn,
        BnImage,
    },
    props: {
        modelValue: String,
        isPublic: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default() {
                return {
                    height: 100,
                    width: 100,
                };
            },
        },
        xl: {
            type: Boolean,
            default: false,
        },
        contactEdit: Boolean,
        avatar: Boolean,
        label: String,
        alt: String,
        resource: Object,
        placeholder: {
            type: Boolean,
            default: false,
        },
        vocab: String,
    },
    emits: ['update:modelValue'],
    data() {
        return {
            imgSrc: this.value || image,
            disableCropAndSave: true,
            defaultOptions: this.options,
            cropperImg: image,
            buttons: 4,
            loading: false,
            editImage: false,
        };
    },
    computed: {
        dimensions() {
            let width = this.options.size || this.options.width || this.options.height;
            let height = this.options.size || this.options.height || width;
            return { width: width, height: height };
        },
    },
    methods: {
        async cropAndSaveImage() {
            // get image data for post processing, e.g. upload or setting image src
            if (this.disableCropAndSave) {
                // todo: notify the user that an image needs to be selected first?
                this.toggleEditImage();
                return;
            }

            this.loading = true;

            let items = {};

            for (const size of Object.keys(sizes).filter((sz) => sz !== 'xl' || this.xl)) {
                const canvas = this.$refs.cropper.getCroppedCanvas({
                    width: sizes[size.toString()],
                    height: sizes[size.toString()],
                    fillColor: '#fff',
                    imageSmoothingQuality: 'high',
                });

                if (canvas) {
                    let canvasPromise = new Promise(function (resolve) {
                        canvas.toBlob(
                            (blob) => {
                                resolve(blob);
                            },
                            'image/jpeg',
                            1,
                        );
                    });

                    let blob = await canvasPromise;
                    const formData = new FormData();
                    formData.append('size', blob);
                    const data = formData.entries().next().value[1];
                    items[size.toString()] = data;
                }
            }

            let imageUpload = await fileApi.uploadImages(items, this.isPublic);
            if (imageUpload) {
                this.url = imageUpload.url;
                this.$emit('update:modelValue', this.url);
                this.imgSrc = imageUpload.url;
                this.loading = false;
                this.toggleEditImage();
            }
        },
        setImage(file) {
            // Cancel option selected on dialog when a file has already been selected
            if (!file) {
                return;
            }
            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }

            if (typeof FileReader === 'function') {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.cropperImg = event.target.result;

                    this.$refs.cropper.replace(event.target.result);
                };
                reader.readAsDataURL(file);
                this.disableCropAndSave = false;
            } else {
                alert('Sorry, FileReader API not supported');
            }
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        toggleEditImage() {
            this.editImage = !this.editImage;
        },
        isNativePlatform() {
            if (Capacitor.isNativePlatform()) {
                this.clickImage();
            } else {
                console.log(this.$refs.fileInput);
                return this.$refs.fileInput.$el.querySelector('input').click();
            }
        },
        async clickImage() {
            await Camera.getPhoto({
                quality: 100,
                allowEditing: false,
                resultType: CameraResultType.Base64,
            }).then((image) => {
                let baseImage = 'data:image/png;base64,' + image.base64String;
                this.cropperImg = baseImage;
                this.$refs.cropper.replace(this.cropperImg);
                this.disableCropAndSave = false;
            });
        },
    },
};
</script>

<template>
    <div class="pointer">
        <div v-if="label" class="text-caption">{{ label }}</div>
        <v-badge v-if="!contactEdit" location="bottom end" color="primary" @click="toggleEditImage">
            <template #badge>
                <font-awesome-icon size="lg" :icon="['far', 'camera']" />
            </template>
            <bn-avatar v-if="avatar && imgSrc" :resource="resource" :size="dimensions.width" :is-public="isPublic" :alt="resource.resourceType + ' image'" />
            <bn-image v-else contain :img-src-prop="imgSrc" :is-public="isPublic" :placeholder="placeholder" :img-width="dimensions.width" :img-height="dimensions.height" :alt="alt" />
        </v-badge>
        <div v-else class="d-flex align-center">
            <bn-avatar
                v-if="avatar && imgSrc"
                :resource="resource"
                :size="dimensions.width"
                :is-public="isPublic"
                :class="{ usprivacy: getUSPrivacy(resource) }"
                :alt="resource.resourceType + ' image'"
            />
            <bn-image v-else contain :img-src-prop="imgSrc" :is-public="isPublic" :placeholder="placeholder" :img-width="dimensions.width" :img-height="dimensions.height" :alt="alt" />
            <v-btn color="primary" variant="outlined" size="x-small" class="ml-3" @click="toggleEditImage">
                Edit photo
                <template #prepend>
                    <font-awesome-icon :icon="['far', 'camera']" />
                </template>
            </v-btn>
        </div>

        <v-dialog v-model="editImage" scrollable :fullscreen="$vuetify.display.xs" width="450px" persistent>
            <v-card>
                <ion-header>
                    <ion-toolbar>
                        <v-toolbar density="compact" flat class="flex-grow-0">
                            <v-toolbar-title flat> {{ vocab }} picture </v-toolbar-title>
                            <bn-toolbar-btn type="text" label="Cancel" icon="times" color="secondary" data-test="closeLogoModal" @click="toggleEditImage"></bn-toolbar-btn>
                            <bn-toolbar-btn type="outlined" right label="Upload" :icon="loading ? 'spinner' : 'check'" color="primary" @click="cropAndSaveImage"></bn-toolbar-btn>
                        </v-toolbar>
                    </ion-toolbar>
                </ion-header>
                <v-divider />
                <v-card-text class="pt-4" :class="{ 'image-uploader-avatar': avatar }">
                    <div align="center" @click="cropperImg.includes('placeholder') ? isNativePlatform : ''">
                        <vue-cropper ref="cropper" class="usprivacy mb-1" :src="cropperImg" :aspect-ratio="dimensions.width / dimensions.height" />
                        <v-btn-toggle v-model="buttons" data-test="cropperOptions">
                            <v-btn color="primary" variant="outlined" size="small" @click.prevent="zoom(-0.2)">
                                <font-awesome-icon size="lg" :icon="['far', 'search-minus']" />
                            </v-btn>
                            <v-btn color="primary" variant="outlined" size="small" @click.prevent="zoom(0.2)">
                                <font-awesome-icon size="lg" :icon="['far', 'search-plus']" />
                            </v-btn>
                            <v-btn color="primary" variant="outlined" size="small" @click.prevent="rotate(-90)">
                                <font-awesome-icon size="lg" :icon="['far', 'undo']" />
                            </v-btn>
                            <v-btn color="primary" variant="outlined" size="small" @click.prevent="rotate(90)">
                                <font-awesome-icon size="lg" :icon="['far', 'redo']" />
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer />
                    <bn-toolbar-btn type="text" label="Select image" icon="camera" variant="outlined" color="primary" @click="isNativePlatform"></bn-toolbar-btn>
                    <v-file-input ref="fileInput" class="d-none" accept="image/png, image/jpeg, image/bmp, image/webp" :loading="loading" @update:model-value="setImage"></v-file-input>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
@import 'cropperjs/dist/cropper.css';
.pointer {
    cursor: pointer;
}

.image-uploader-avatar .cropper-view-box {
    border-radius: 50%;
}
</style>
