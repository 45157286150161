import { ref, watch, computed, onMounted, onBeforeUnmount, nextTick } from 'vue';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { createFhirResource, Resource } from '@/fhirworks';
import config, { isRetryableError } from '@/common/autoSaveConfig';
import { getCacheHeaders } from '@/common/api/cacheHeaders.config';

import debounce from 'lodash/debounce';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { getUrlFromCanonical } from '@/common/core';
import { until } from '@vueuse/core';

export default {
    name: 'DataProvider',
    props: {
        modelValue: Object | Array,
        resource: Object | Array,
        link: Object | Array,
        total: Object | Array | Number,
        loading: Boolean,
        saving: Boolean,
        query: Object | Array,
        autosave: Boolean,
        autosaveConfig: Object,
        autosaveWhen: Function,
        saveTimeout: Number, // timeout for saves in ms, 0 for no timeout, default is 30 defined in httpFhir.service.js
        validator: Object | Array,
        collection: Boolean,
        subscribe: String | Array,
        /**
         * This prop is used to refresh the data-provider when the :query changes.
         * for example getting related contacts for a contact in a select box.  you want to refresh every time you change the contact dropdown.
         */
        refreshOnChange: Boolean,
        errorHandle: {
            type: Boolean,
            default: true,
        },
    },
    emits: [
        'update:modelValue',
        'update:link',
        'update:total',
        'update:loading',
        'update:saving',
        'saved',
        'saving',
        'needsSaved',
        'saveError',
        'loaded',
        'loading',
        'loadError',
        'serverPush',
        'loadingItems',
        'loadedItems',
        'error',
        'itemRemoved',
    ],
    setup(props, { expose, slots, emit }) {
        const data = ref(null);
        const saveFailed = ref(false);
        const isLoading = ref(true);
        const isSaving = ref(false); // value for other to tie into to see if data-provider is saving
        const isSavingToServer = ref([]); // value to see if we are actively sending data to server
        const hasPendingSave = ref([]); // used to determine if autosave functionality has a pending save so it can ignore subsequent requests.
        const loadError = ref(null);
        const saveError = ref(null);
        const autosaveFunc = ref([]);

        const computedData = computed(() => {
            return data.value ? JSON.parse(JSON.stringify(data.value)) : undefined;
        });

        const parseResource = (resource) => {
            if (!resource || isEmpty(resource) || resource instanceof Resource || (Array.isArray(resource) && resource.every((r) => r instanceof Resource))) {
                return resource;
            }

            if (Array.isArray(resource)) {
                return resource.map((r) => createFhirResource(null, r));
            }

            return createFhirResource(null, resource);
        };

        if (props.collection) {
            data.value = [];
            saveError.value = [];
        }
        if (props.resource) {
            if (!props.collection && props.query) {
                throw new Error('Invalid props for non collection provider, You can’t have both resource and query props without a collection prop');
            }
            data.value = parseResource(props.resource);
            isLoading.value = false;
            emit('update:loading', false);
            emit('update:modelValue', data.value);
        }

        onMounted(() => {
            if (props.resource && !props.query) {
                emit('loaded');
            }
        });

        onBeforeUnmount(() => {
            autosaveFunc.value.forEach((func) => func.flush());

            autosaveFunc.value = [];
            if (props.collection) {
                data.value = [];
            } else {
                data.value = null;
            }
            emit('update:modelValue', data.value);
        });

        watch(
            () => props.resource,
            (newVal, oldVal) => {
                // if the value needsSaved() we know the watcher is from the resource being changed so return;
                if (!newVal || isEqual(newVal, oldVal) || (!props.collection && newVal?.needsSaved())) return;

                if (!props.collection) {
                    data.value = parseResource(props.resource) || null;
                    if (props.query) {
                        fetch();
                    }
                } else {
                    data.value = parseResource(props.resource) || [];
                    if (props.query) {
                        fetchCollection(props.refreshOnChange);
                    }
                }
            },
            { deep: true },
        );

        watch(
            () => data.value,
            () => {
                if (typeof data.value?.needsSaved === 'function') {
                    emit('needsSaved', data.value?.needsSaved());
                }

                // if ANY item needs save, update needsSaved
                if (props.collection && Array.isArray(data.value) && data.value?.some((d) => typeof d?.needsSaved === 'function')) {
                    emit(
                        'needsSaved',
                        data.value?.some((d) => typeof d?.needsSaved === 'function' && d?.needsSaved()),
                    );
                }
            },
            { deep: true },
        );

        watch(
            () => computedData.value,
            (newVal, oldVal) => {
                emit('update:modelValue', data.value);
                if (!oldVal || !props.autosave) return;

                isSaving.value = true;
                emit('update:saving', true);
                emit('saving');

                let indexes = [0];
                if (props.collection) {
                    if (newVal && oldVal && newVal.length !== oldVal.length) {
                        isSaving.value = false;
                        emit('update:saving', false);
                        return;
                    }
                    // get the differences in items in data provider to process.
                    let QRsToProcess = differenceWith(newVal, oldVal, isEqual);

                    indexes = QRsToProcess.map((qr) => newVal.findIndex((obj) => isEqual(obj, qr)));
                    let changedIndexes = indexes;

                    for (let i = 0; i < changedIndexes.length; i++) {
                        let index = changedIndexes[i];

                        let newValWithoutJson = cloneDeep(newVal[index]);
                        let oldValWithoutJson = cloneDeep(oldVal[index]);

                        if (
                            newValWithoutJson &&
                            oldValWithoutJson &&
                            Object.prototype.hasOwnProperty.call(newValWithoutJson, 'originalObjJson') &&
                            Object.prototype.hasOwnProperty.call(oldValWithoutJson, 'originalObjJson')
                        ) {
                            newValWithoutJson.originalObjJson = null;
                            oldValWithoutJson.originalObjJson = null;

                            // Make sure changing of originalObjJson (altered when saving), doesn't trigger another save.
                            if (!isEqual(newVal[index].originalObjJson, oldVal[index].originalObjJson) && isEqual(newValWithoutJson, oldValWithoutJson)) {
                                indexes = indexes.filter((i) => i !== index);
                            }
                        }
                    }
                } else {
                    // not a collection
                    let newValWithoutJson = cloneDeep(newVal);
                    let oldValWithoutJson = cloneDeep(oldVal);

                    if (Object.prototype.hasOwnProperty.call(newValWithoutJson, 'originalObjJson') && Object.prototype.hasOwnProperty.call(oldValWithoutJson, 'originalObjJson')) {
                        newValWithoutJson.originalObjJson = null;
                        oldValWithoutJson.originalObjJson = null;

                        // Make sure changing of originalObjJson (altered when saving), doesn't trigger another save.
                        if (!isEqual(newVal.originalObjJson, oldVal.originalObjJson) && isEqual(newValWithoutJson, oldValWithoutJson)) {
                            isSaving.value = false;
                            emit('update:saving', false);
                            return;
                        }
                    }
                }

                if (!indexes.length) {
                    isSaving.value = false;
                    emit('update:saving', false);
                    return;
                }

                for (let i = 0; i < indexes.length; i++) {
                    let index = indexes[i];

                    if (
                        (props.autosave && !props.collection) ||
                        (props.autosave && props.collection && typeof props.autosaveWhen !== 'function') ||
                        (props.autosave && props.collection && typeof props.autosaveWhen === 'function' && props.autosaveWhen(data.value[index]))
                    ) {
                        if (typeof autosaveFunc.value[index] !== 'function') {
                            autosaveFunc.value[index] = debounce(
                                async (itemToSave) => {
                                    if (!isSavingToServer.value[index]) {
                                        await save(itemToSave, props.autosaveConfig);
                                    } else if (hasPendingSave.value[index]) {
                                        return;
                                    } else {
                                        hasPendingSave.value[index] = true;
                                        let waitForSavedToServer = new Promise((resolve) => {
                                            let interval = setInterval(() => {
                                                if (isSavingToServer.value[index]) {
                                                    return;
                                                }

                                                clearInterval(interval);
                                                resolve();
                                            }, 100);
                                        });

                                        await waitForSavedToServer;
                                        await save(itemToSave, props.autosaveConfig, index);
                                        hasPendingSave.value[index] = false;
                                    }
                                },
                                config.debounceTime,
                                { maxWait: config.maxTime },
                            );
                        }

                        autosaveFunc.value[index](props.collection ? data.value[index] : null);
                    }
                }
            },
            { deep: true },
        );

        const getByType = (type) => {
            return getBy((item) => item.resourceType === type);
        };

        const getBy = (filter) => {
            if (!data.value) return;
            return data.value.filter(filter);
        };

        const getOneById = (id) => {
            return getOneBy((item) => item.id === id);
        };

        const getOneBy = (filter) => {
            return data.value.find(filter);
        };

        const refresh = () => {
            !props.collection ? fetch() : fetchCollection(true);
        };

        const waitForSave = async () => {
            return new Promise(async (resolve) => {
                await until(isSaving).toBe(false, { timeout: 10000 });
                resolve();
            });
        };

        const waitForLoad = async () => {
            return new Promise(async (resolve) => {
                await until(isLoading).toBe(false, { timeout: 10000 });
                resolve();
            });
        };

        const fetch = () => {
            if (Array.isArray(props.query)) {
                throw new Error('You cannot use an array with query prop for non collection autosave.');
            }

            if (!props.query?.id && !props.query?.resourceType && !props.query?.url) {
                return;
            }

            isLoading.value = true;
            emit('update:loading', true);
            emit('loading');

            // allow for custom queries rather than just simple fetch by ID
            let args = [];
            if (props.query.url) {
                // You can pass a fully qualified URL as the query and it will be loaded.
                // Helpful when querying an external resource such as the library server
                // { query: { url: 'https://fhir.bestnotes.com/Entity?something=somethingelse' } }
                args.push(props.query.url);
            } else if (props.query.id) {
                args.push(props.query.resourceType + '/' + props.query.id);
            } else {
                args.push(props.query.resourceType + '?' + props.query.query);
            }
            let config = {};

            if (props.query?.terminology) {
                let cacheHeaders = getCacheHeaders(3600);
                config.headers = cacheHeaders;

                if (!import.meta.env.VITE_TERMINOLOGY_LOCAL) {
                    config.baseURL = import.meta.env.VITE_TERMINOLOGY_URL || 'https://terminology.fhir.bestnotesfhir.com';
                }
            }

            if (props.query?.cache !== null && props.query?.cache !== undefined) {
                let time = typeof props.query?.cache === 'number' ? props.query?.cache : 0;
                let cacheHeaders = getCacheHeaders(time);
                if (cacheHeaders) config.headers = cacheHeaders;
            }

            if (!props.errorHandle) {
                config.errorHandle = false;
            }

            if (!isEmpty(config)) {
                args.push(config);
            }

            $httpFhirApi
                .get(...args)
                .then((response) => {
                    loadError.value = null;
                    // custom queries return data as an array by default - return item 0
                    data.value = Array.isArray(response.data) ? response.data[0] : response.data;
                    emit('update:modelValue', data.value);
                    isLoading.value = false;
                    emit('update:loading', false);
                    nextTick(() => {
                        emit('loaded');
                    });
                })
                .catch((error) => {
                    loadError.value = error;
                    isLoading.value = false;
                    emit('update:loading', false);
                    nextTick(() => {
                        emit('loadError', error);
                    });
                });
        };

        const fetchCollection = (isRefresh = false) => {
            isLoading.value = true;
            emit('update:loading', true);
            emit('loading');
            fetchItemPromise(props.query)
                .then(async (results) => {
                    loadError.value = null;
                    let existingResults = data.value;
                    if (isRefresh) {
                        existingResults = parseResource(props.resource) || [];
                    }

                    data.value = existingResults.concat(results.data || results);
                    // remove duplicates (can be caused by overlapping queries - specifically include/revinclude since they are not automatically de-duped.)
                    data.value = uniqBy(data.value, 'id');
                    // include pagination links
                    // link = results.link;

                    emit('update:link', results.link);
                    emit('update:total', results.total);
                    emit('update:modelValue', data.value);
                    isLoading.value = false;
                    emit('update:loading', false);
                    await nextTick();
                    emit('loaded');
                })
                .catch((error) => {
                    loadError.value = error;
                    isLoading.value = false;
                    emit('update:loading', false);
                    emit('loadError', error);
                });
        };

        const fetchItems = (query) => {
            emit('loadingItems');
            fetchItemPromise(query)
                .then((results) => {
                    data.value = data.value.concat(results.data || results);
                    // remove duplicates (can be caused by overlapping queries - specifically include/revinclude since they are not automatically de-duped.)
                    data.value = uniqBy(data.value, 'id');
                    // include pagination links
                    // link.value = results.link;

                    emit('update:link', results.link);
                    emit('update:total', results.total);
                    emit('update:modelValue', data.value);
                    nextTick(() => {
                        emit('loadedItems', results.link);
                    });
                })
                .catch((error) => {
                    emit('error', error);
                });
        };

        const fetchItemPromise = (query) => {
            let queries = !Array.isArray(query) ? [query] : query;

            let promises = [];
            let data = [];
            let link = [];
            let total = 0;

            queries
                .filter((query) => query?.resourceType || query?.url || query?.canonicalUrl)
                .forEach((query) => {
                    let args = [];
                    let config = {};

                    if (query.id) {
                        args.push(query.resourceType + '?id=' + query.id);
                    } else if (query.canonicalUrl) {
                        let baseUrl = getUrlFromCanonical(query.canonicalUrl).fullUrl;
                        // Finally, add any query information passed
                        if (query.query) {
                            baseUrl += '&' + object2QueryString(query.query).substr(1);
                        }
                        args.push(baseUrl);
                    } else if (query.url) {
                        args.push(query.url);
                    } else if (query.query) {
                        args.push(query.resourceType + object2QueryString(query.query));
                    } else {
                        args.push(query.resourceType);
                    }

                    if (query?.terminology) {
                        let cacheHeaders = getCacheHeaders(3600);
                        config.headers = cacheHeaders;
                        config.terminology = true;

                        if (!import.meta.env.VITE_TERMINOLOGY_LOCAL) {
                            config.baseURL = import.meta.env.VITE_TERMINOLOGY_URL || 'https://terminology.fhir.bestnotesfhir.com';
                        }
                    }

                    if (query?.cache !== null && query?.cache !== undefined) {
                        let time = typeof query?.cache === 'number' ? query?.cache : 0;
                        let cacheHeaders = getCacheHeaders(time);
                        if (cacheHeaders) config.headers = cacheHeaders;
                    }

                    if (!props.errorHandle) {
                        config.errorHandle = false;
                    }

                    if (!isEmpty(config)) {
                        args.push(config);
                    }

                    promises.push($httpFhirApi.get(...args));
                });

            if (!promises.length) {
                return Promise.reject();
            }

            // return new Promise((resolve, reject) => {
            //     Promise.all(promises)
            //         .then((values) => {
            //             values.forEach((value) => {
            //                 data = data.concat(value.data);
            //                 link = link.concat(value.originalData?.link);
            //             });
            //             resolve({ data: data, link: link });
            //         })
            //         .catch((error) => {
            //             reject(error);
            //         });
            // });

            let waitForSave = new Promise(async (resolve) => {
                await until(isSaving).toBe(false, { timeout: 10000 });
                resolve();
            });

            return new Promise((resolve, reject) => {
                Promise.all([waitForSave]).then(() => {
                    Promise.all(promises)
                        .then((values) => {
                            values.forEach((value) => {
                                data = data.concat(value.data);
                                link = link.concat(value.originalData?.link);
                                total = value?.total;
                            });
                            resolve({ data, link, total });
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            });
        };

        const addItem = async (item, saveItem) => {
            let newItem;
            if (props.collection) {
                data.value.push(item);
                // if autosave == false then we can save the new item by passing in bool 'save' as second param
                if (saveItem) {
                    newItem = await save(item);
                }
            }
            return newItem;
        };

        const removeItem = async (item) => {
            // if item removed was the only item then return
            if (!Array.isArray(data.value)) return;

            // Allows for removing and item if it doesn't have an id defined
            let itemIndex = data.value.indexOf(item);
            if (itemIndex !== -1) {
                let [itemRemoved] = data.value.splice(itemIndex, 1);
                emit('itemRemoved', itemRemoved);
                return;
            }

            // Item not found see if we can look it up based on id and type
            if (typeof item.id === 'string' && typeof item.resourceType === 'string') {
                itemIndex = data.value.findIndex((elem) => elem.resourceType === item.resourceType && elem.id === item.id);

                if (itemIndex !== -1) {
                    let [itemRemoved] = data.value.splice(itemIndex, 1);
                    emit('itemRemoved', itemRemoved);
                }
            }
        };

        const deleteItem = async (itemToDelete) => {
            let data = itemToDelete || data.value;
            let config = { errorHandle: false };

            let id, resourceType, responseStatus;
            if (typeof data === 'object') {
                id = data.id;
                resourceType = data.resourceType;
            }

            if (typeof id === 'string' && typeof resourceType === 'string') {
                try {
                    let response = await $httpFhirApi.delete(resourceType + '/' + id, config);
                    responseStatus = response.status === 200 || response.status === 204;
                } catch (error) {
                    emit('error', error);
                    return;
                }
            }

            if (responseStatus === undefined || responseStatus) {
                return removeItem(itemToDelete);
            }
        };

        const save = async (itemToSave, propertyConfig, index = 0) => {
            let localData = itemToSave || data.value;
            let returnItem = undefined;
            isSaving.value = true;
            isSavingToServer.value[index] = true;
            emit('update:saving', true);
            emit('saving');

            let config = { headers: { ContentType: 'application/json-patch+json' }, errorHandle: false };

            if (props.saveTimeout) {
                config.timeout = props.saveTimeout;
            }

            // if autosave is turned on.  We want to ALWAYS save even if it's invalid, so ignore validation rules here
            if (props.validator && !props.autosave) {
                let validator = props.validator;
                if (Object.prototype.hasOwnProperty.call(validator, '$invalid') && validator.$invalid) {
                    emit('update:saving', false);
                    isSaving.value = false;
                    isSavingToServer.value[index] = false;
                    return;
                }

                if (!Array.isArray(validator)) {
                    validator = [validator];
                }

                let isValid = true;
                validator.forEach((v) => {
                    if (Object.prototype.hasOwnProperty.call(v, 'filter') && v.filter(localData) && v.validator.$invalid) {
                        isValid = false;
                    }
                });

                if (!isValid) {
                    emit('update:saving', false);
                    isSaving.value = false;
                    isSavingToServer.value[index] = false;
                    return;
                }
            }

            if (localData.id && !localData.temp.isNew) {
                /**
                 * data.temp.isNew = true will bypass this section and force a new resource to be created
                 * with the specified ID. This allows us to use data-provider to create a new record with an
                 * ID generated in the application that can be used to link to other resources.
                 */

                // The resourceJsonPatch method uses the originalObjJson property to generate
                // the proper JsonPatch so we have to make sure that represents the object that is on the server.
                let patch = localData.resourceJsonPatch();
                if (!patch.length) {
                    emit('update:saving', false);
                    emit('saved');
                    isSaving.value = false;
                    isSavingToServer.value[index] = false;
                    return;
                }

                if (propertyConfig?.exclude) {
                    patch = patch.filter((i) => !propertyConfig.exclude.some((j) => i.path.match(j)));
                }

                if (propertyConfig?.include) {
                    patch = patch.filter((i) => propertyConfig.include.some((j) => i.path.match(j)));
                }

                try {
                    await $httpFhirApi.patch(localData.resourceType + '/' + localData.id + '?_method=json-patch', patch, config);
                    localData.setObjectSaved(patch);
                    returnItem = localData;

                    if (itemToSave?.id) {
                        let index = data.value.findIndex((e) => e.id === itemToSave.id);
                        data.value[index] = itemToSave;
                    }
                    if (props.collection) {
                        let index = data.value.findIndex((obj) => isEqual(obj, localData));
                        saveError.value[index] = null;
                    } else {
                        saveError.value = null;
                    }
                } catch (error) {
                    if (props.collection) {
                        let index = data.value.findIndex((obj) => isEqual(obj, localData));
                        saveError.value[index] = error;
                    } else {
                        saveError.value = error;
                    }
                    emit('saveError', error);
                    if (!isRetryableError(error)) {
                        emit('update:saving', false);
                        isSaving.value = false;
                        isSavingToServer.value[index] = false;
                        return;
                    }

                    // store.dispatch('addSaveToRetry', {
                    //     name: module.toString() + '-' + itemName.toString(),
                    //     save: async () => {
                    //         await store.dispatch(module + '/patch', { id: formItem.id, name: itemName, data: patch, config: config });
                    //     },
                    // });
                }
            } else {
                try {
                    let savedItem = await $httpFhirApi.post(localData.resourceType, localData.toJSON(), config);
                    let newItem = createFhirResource(savedItem.data.resourceType, savedItem.data.toJSON());
                    returnItem = newItem;

                    if (props.collection) {
                        let index = data.value.findIndex((obj) => isEqual(obj, localData));
                        if (index !== -1) {
                            data.value.splice(index, 1, newItem);
                            saveError.value[index] = null;
                        } else {
                            data.value.push(newItem);
                        }
                    } else {
                        data.value = newItem;
                        saveError.value = null;
                    }
                    emit('update:modelValue', data.value);
                } catch (error) {
                    if (props.collection) {
                        let index = data.value.findIndex((obj) => isEqual(obj, localData));
                        saveError.value[index] = error;
                    } else {
                        saveError.value = error;
                    }
                    emit('saveError', error);
                    if (!isRetryableError(error)) {
                        emit('update:saving', false);
                        isSaving.value = false;
                        isSavingToServer.value[index] = false;
                        return;
                    }

                    // store.dispatch('addSaveToRetry', {
                    //     name: module.toString() + '-' + itemName.toString(),
                    //     save: async () => {
                    //         await store.dispatch(module + '/patch', { id: formItem.id, name: itemName, data: patch, config: config });
                    //     },
                    // });
                }
            }

            emit('update:saving', false);
            emit('saved');
            isSaving.value = false;
            isSavingToServer.value[index] = false;
            return returnItem;
        };

        const object2QueryString = (query) => {
            if (typeof query === 'string') return '?' + encodeURI(query);

            let queryString = '';

            if (query) {
                queryString = Object.keys(query)
                    .map((key) => key + '=' + query[key])
                    .join('&');
            }
            if (queryString) {
                queryString = '?' + queryString;
            }
            return queryString;
        };

        watch(
            () => props.query,
            (newVal, oldVal) => {
                if (!newVal || isEqual(newVal, oldVal)) return;
                isSaving.value = false;
                !props.collection ? fetch() : fetchCollection(props.refreshOnChange);
            },
            { immediate: true, deep: true },
        );

        expose({ data, refresh, fetchItems, save, addItem, removeItem, deleteItem, getByType, getBy, getOneBy, getOneById, waitForSave, waitForLoad });

        return () => {
            // if data is provided to it directly. we don't need these slots here
            if (!props.resource) {
                if (isLoading.value && slots.loading) {
                    return slots.loading({
                        isLoading: isLoading.value,
                    });
                }

                if (loadError.value && slots.error) {
                    return slots.error({
                        refresh: refresh,
                        error: loadError.value,
                        loadError: loadError.value,
                    });
                }
            }

            if (!isLoading.value && props.collection && data.value && data.value.length === 0 && slots.noData) {
                return slots.noData({
                    refresh: refresh,
                    fetchItems: fetchItems,
                    addItem: addItem,
                });
            }

            if (!isLoading.value && slots.default) {
                return slots.default({
                    data: data.value,
                    link: props.link,
                    total: props.total,
                    isSaving: isSaving.value,
                    saveError: saveError.value,

                    refresh: refresh,
                    fetchItems: fetchItems,
                    save: save,
                    addItem: addItem,
                    removeItem: removeItem,
                    deleteItem: deleteItem,
                    getByType: getByType,
                    getBy: getBy,
                    getOneBy: getOneBy,
                    getOneById: getOneById,
                    waitForSave: waitForSave,
                    waitForLoad: waitForLoad,
                });
            }
        };
    },
};
