<script>
import { types as FHIRWorksTypes } from '@/fhirworks';
import BnListBox from '@/components/BnListBox.vue';
import DataProvider from '@/components/DataProvider.js';
import BnDialog from '@/components/BnDialog.vue';
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { addRefToBackBtnListiner, removeRefToBackBtnListiner } from '@/common/nativeBackButton';

export default {
    name: 'BnListIdentifiers',
    components: { BnToolbarBtn, BnDialog, DataProvider, BnListBox },
    props: {
        modelValue: Array,
        viewOnly: Boolean,
        title: String,
        type: String,
    },
    emits: ['update:modelValue'],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            dpData: [],
            activeItem: {},
            listBox: {
                title: this.title || 'Identifiers',
                icon: 'id-badge',
                addLabel: 'Add identifier',
            },
            dialog: {
                title: 'Identifier',
            },
            isNew: true,
        };
    },
    computed: {
        dpDataQuery() {
            return {
                query: 'system=http://code.system/identifier-types&hierarchy=All,Practice/Location/Provider,Practice/Location,Practice/Provider&_sort=display',
                resourceType: 'Concept',
                terminology: true,
            };
        },
        computedDpData() {
            let items = this.dpData.map((item) => {
                // do not allow Organizations to list ssn or ein for users
                if (((this.type === 'Organization' || this.type === 'Location') && item.code !== 'SY') || (this.type === 'Practitioner' && item.code !== 'EI')) {
                    return { system: item.system, code: item.code, display: item.display };
                }
            });
            return items.sort((a, b) => (a.display > b.display ? 1 : -1)).filter((e) => e);
        },
        activeItems() {
            return this.modelValue
                .map((item, index) => {
                    return { index: index, display: item.type.codingZero.display, value: item.value, type: item.type };
                })
                .sort((a, b) => (a.display > b.display ? 1 : -1));
        },
        isValidResource() {
            return !!this.activeItem.type;
        },
    },
    methods: {
        async addItemDialog() {
            if (this.viewOnly) return;
            this.isNew = true;
            this.activeItem = {};
            const options = {
                header: false,
                width: 400,
                fullscreen: true,
            };
            addRefToBackBtnListiner(this.$refs.editItemDialog, 'close', 'editItemDialog');
            this.$refs.editItemDialog.show(options).then(async (save) => {
                removeRefToBackBtnListiner('editItemDialog');
                if (!save) return;
                const list = [...this.modelValue];
                list.push(new FHIRWorksTypes.Identifier({ value: this.activeItem.value, type: { coding: [this.activeItem.type] } }));
                this.$emit('update:modelValue', list);
                this.$refs.editItemDialog.cancel();
            });
        },
        editItemDialog(item) {
            if (this.viewOnly) return;
            this.isNew = false;
            this.activeItem = { ...item, disabled: true, type: item.type.codingZero };
            const options = {
                header: false,
                width: 400,
            };
            addRefToBackBtnListiner(this.$refs.editItemDialog, 'close', 'editItemDialog');
            this.$refs.editItemDialog.show(options).then(async (save) => {
                removeRefToBackBtnListiner('editItemDialog');
                if (!save) return;
                const list = this.modelValue.filter((i, index) => index !== item.index);
                list.push(new FHIRWorksTypes.Identifier({ value: this.activeItem.value, type: { coding: [this.activeItem.type] } }));
                this.$emit('update:modelValue', list);
            });
        },
        removeItem(item) {
            if (this.viewOnly) return;
            const list = this.modelValue.filter((i, index) => index !== item.index);
            this.$emit('update:modelValue', list);
        },
    },
    validations() {
        return {
            activeItem: {
                type: {
                    required,
                },
                value: {
                    einLength: (val, item) => {
                        let type = item.type.code;
                        return !(type === 'EI' && val.length !== 9);
                    },
                    npiLength: (val, item) => {
                        let type = item.type.code;
                        return !(type === 'XX' && val.length !== 10);
                    },
                    ssnLength: (val, item) => {
                        let type = item.type.code;
                        return !(type === 'SY' && val.length !== 9);
                    },
                    required,
                },
            },
        };
    },
};
</script>

<template>
    <div v-if="activeItem">
        <data-provider v-model="dpData" collection :query="dpDataQuery" />
        <bn-list-box
            :title="listBox.title"
            :items="activeItems"
            :item-text="{ title: 'display', subtitle: 'value' }"
            :item-icon="listBox.icon"
            :add="viewOnly ? false : listBox.addLabel"
            add-icon="plus"
            :remove="!viewOnly"
            :clickable="!viewOnly"
            :data-cy="$attrs['data-cy']"
            @click="editItemDialog"
            @add="addItemDialog"
            @remove="removeItem"
        />
        <bn-dialog ref="editItemDialog">
            <v-card flat class="d-flex flex-column">
                <ion-header>
                    <ion-toolbar>
                        <v-toolbar density="compact" flat>
                            <v-toolbar-title>{{ dialog.title }}</v-toolbar-title>
                            <bn-toolbar-btn type="text" :label="isNew ? 'Discard' : 'Cancel'" :icon="isNew ? 'trash' : 'times'" @click="$refs.editItemDialog.cancel()" />
                            <bn-toolbar-btn
                                :disabled="!!v$.activeItem.$errors.length"
                                type="outlined"
                                :label="isNew ? 'Add' : 'Update'"
                                :icon="isNew ? 'plus' : 'check'"
                                color="primary"
                                data-cy="saveIdentifiers"
                                @click="$refs.editItemDialog.confirm()"
                            />
                        </v-toolbar>
                    </ion-toolbar>
                </ion-header>
                <v-divider />
                <div class="pa-4">
                    <v-autocomplete
                        v-model="activeItem.type"
                        :items="computedDpData"
                        v-bind="$bnVuelidateErrorExtractor(v$.activeItem, 'type', true)"
                        item-title="display"
                        label="Type"
                        persistent-hint
                        return-object
                        data-cy="identifiers"
                        @blur="v$.activeItem.type.$touch()"
                        @update:model-value="$refs.activeItemValue.focus()"
                    ></v-autocomplete>
                    <v-text-field
                        ref="activeItemValue"
                        v-model="activeItem.value"
                        label="Value"
                        v-bind="$bnVuelidateErrorExtractor(v$.activeItem, 'value', true)"
                        data-cy="identifiersValue"
                        @blur="v$.activeItem.value.$touch()"
                    >
                    </v-text-field>
                </div>
            </v-card>
        </bn-dialog>
        <bn-dialog ref="bnDialog" />
    </div>
</template>
