<script>
import BnImage from '@/components/BnImage.vue';
import { getContactType } from '@/common/core';
import BnResourceIcon from '@/components/BnResourceIcon.vue';

export default {
    name: 'BnAvatar',
    components: {
        BnResourceIcon,
        BnImage,
    },
    props: {
        resource: Object,
        title: String,
        size: [String, Number],
        isPublic: Boolean,
        noPhoto: {
            type: Boolean,
            default: false,
        },
        alt: String,
        badge: {
            type: Boolean,
            default: true,
        },
        icon: String,
        iconSize: {
            type: String,
            default: undefined,
        },
        dot: {
            type: Boolean,
            default: true,
        },
        color: String,
        noBadge: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            imageLoaded: false,
            invalidImage: false,
        };
    },
    computed: {
        photo() {
            if (this.noPhoto || this.invalidImage) {
                return false;
            }

            return this.resource?.logo || this.resource?.photo?.[0]?.url || this.resource?.photo?.url;
        },
        resourceType() {
            if (typeof this.resource === 'object') {
                return getContactType(this.resource);
            } else {
                return this.resource;
            }
        },
        badgeColor() {
            let colorClass = '';
            switch (this.resourceType?.toLowerCase()) {
                case 'patient':
                    colorClass = 'patient';
                    break;
                case 'person':
                    colorClass = 'related';
                    break;
                case 'practitioner':
                    colorClass = 'practitioner';
                    break;
                case 'user':
                    colorClass = 'user';
                    break;
                case 'organization':
                    colorClass = 'organization';
                    break;
                case 'location':
                case 'room':
                    colorClass = 'related';
                    break;
                default:
                    colorClass = 'grey';
            }
            return colorClass;
        },
        avatarColor() {
            if (!this.resourceType) {
                return 'success';
            }
            return this.color ? this.color : this.badgeColor;
        },
    },
};
</script>

<template>
    <span style="display: flex">
        <v-badge v-if="!noBadge && badge && photo" :dot="dot" :color="badgeColor" location="bottom end">
            <v-avatar :color="avatarColor" :size="size" class="no-select" :class="{ usprivacy: getUSPrivacy(resource) }">
                <font-awesome-icon v-if="icon" :icon="['far', icon]" size="xs" class="text-white" />
                <template v-else>
                    <font-awesome-icon v-if="!imageLoaded" :icon="['far', 'spinner']" class="fa-spin text-white" :size="iconSize" />
                    <bn-image
                        v-if="photo"
                        :hide="!imageLoaded"
                        :img-width="size"
                        :alt="alt"
                        :img-src-prop="photo"
                        :is-public="isPublic"
                        @load="imageLoaded = true"
                        @invalid-image="invalidImage = true"
                    />
                </template>
            </v-avatar>
        </v-badge>
        <v-avatar v-else-if="icon && badge && !photo" :color="badgeColor" :size="size" class="no-select" :class="{ usprivacy: getUSPrivacy(resource) }">
            <font-awesome-icon v-if="icon" :icon="['far', icon]" :size="iconSize" class="text-white" />
        </v-avatar>
        <bn-resource-icon v-else-if="!photo" :resource="resourceType" :size="size" :icon-size="iconSize"></bn-resource-icon>
        <v-avatar v-else-if="photo" :color="avatarColor" :size="size" class="no-select" :class="{ usprivacy: getUSPrivacy(resource) }">
            <font-awesome-icon v-if="icon" :icon="['far', icon]" :size="iconSize" class="text-white" />
            <template v-else>
                <font-awesome-icon v-if="!imageLoaded" :icon="['far', 'spinner']" class="fa-spin text-white" :size="iconSize" />
                <bn-image v-if="photo" :img-width="size" :hide="!imageLoaded" :alt="alt" :img-src-prop="photo" :is-public="isPublic" @load="imageLoaded = true" @invalid-image="invalidImage = true" />
            </template>
        </v-avatar>
    </span>
</template>
