<script setup>
import { ref, computed, watch, onMounted, mergeProps } from 'vue';
import { format, isValid, parse, parseISO } from 'date-fns';
import timezoneApi from '@/common/api/timezone.api';
import { useAuthStore } from '@/stores/auth';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    inputDateFormat: {
        type: String,
    },
    label: String,
    icon: String,
    modelValue: String,
    customClass: {
        type: String,
        default: '',
    },
    errorMessages: {
        type: [Array, String],
        default: '',
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    clearable: {
        type: Boolean,
        default: false,
    },
    dense: {
        type: Boolean,
        default: false,
    },
    hideDetails: {
        type: Boolean,
        default: false,
    },
    dob: Boolean,
    id: String,
    maxDate: {
        type: String,
        default: null,
    },
    minDate: {
        type: String,
        default: null,
    },
    strictDate: {
        type: Boolean,
        default: true,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'change', 'focus', 'blur', 'isDateValid']);

const dateValue = ref(props.modelValue);
const dateFormat = ref('');
const dateFormatted = ref('');
const menu = ref(false);
const activeView = ref('month');

onMounted(() => {
    if (props.inputDateFormat) {
        dateFormat.value = props.inputDateFormat;
    } else {
        const authStore = useAuthStore();
        dateFormat.value = authStore.inputDateFormat || 'MM/dd/yyyy';
    }

    dateValue.value = props.modelValue == null ? undefined : props.modelValue;
    dateFormatted.value = formatDate(props.modelValue);
});

const dateSeparator = computed(() => (dateFormat.value.includes('-') ? '-' : '/'));

const dateMask = computed(() => {
    let fullDateFormat = dateFormat.value.replace(/[a-z]/gi, '#');
    if (props.strictDate) {
        return fullDateFormat;
    } else {
        if (dateFormat.value === 'yyyy-MM-dd') {
            return ['####', '####-##', fullDateFormat];
        } else {
            return ['####', '##/####', fullDateFormat];
        }
    }
});

const validDate = computed(() => {
    let isDateValid = false;

    if (props.strictDate) {
        isDateValid = dateFormatted.value?.length === 10 && isValid(parse(dateFormatted.value, dateFormat.value, new Date()));
        emit('isDateValid', dateFormatted.value === undefined || dateFormatted.value === '' ? true : isDateValid);

        return isDateValid;
    }

    let dateLength = dateFormatted.value?.length || 0;
    switch (dateLength) {
        case 10: {
            let parsedDate = parse(dateFormatted.value, dateFormat.value, new Date());
            isDateValid = isValid(parsedDate);
            break;
        }
        case 7: {
            let dateParts = dateFormatted.value.split(dateSeparator.value);
            let year = dateFormatted.value.indexOf(dateSeparator.value) === 4 ? dateParts[0] : dateParts[1];
            let month = dateFormatted.value.indexOf(dateSeparator.value) === 2 ? dateParts[0] : dateParts[1];

            isDateValid = isValid(new Date(year + '-' + month + '-01'));
            break;
        }
        case 4: {
            isDateValid = isValid(new Date(dateFormatted.value + '-01-01'));
            break;
        }
    }

    emit('isDateValid', dateFormatted.value === undefined || dateFormatted.value === '' ? true : isDateValid);
    return isDateValid;
});

const vueCalDate = computed(() => {
    if (!isValid(new Date(dateValue.value))) return null;

    return format(new Date(timezoneApi.getLocalDateTime(dateValue.value)), 'yyyy-MM-dd');
});

const formatDate = (date) => {
    if (!date) return '';
    if (date.toString() === 'Invalid date') return '';

    // Convert a date object to a string
    if (typeof date === 'object') {
        date = format(date, dateFormat.value);
    }

    let formattedDate = '';
    switch (date.length) {
        case 7: {
            if (dateFormat.value.toLowerCase() === 'yyyy-mm-dd') {
                formattedDate = format(parse(date, 'yyyy-MM', new Date()), 'yyyy' + dateSeparator.value + 'MM');
            } else {
                // Handles both mm/dd/yyyy and dd/mm/yyyy
                formattedDate = format(parse(date, 'yyyy-MM', new Date()), 'MM' + dateSeparator.value + 'yyyy');
            }
            break;
        }
        case 4: {
            formattedDate = format(parse(date, 'yyyy', new Date()), 'yyyy');
            break;
        }
        // handles timestamps that have a time component
        default: {
            let originalDate = date;
            date = parseISO(date);
            if (date.toString() === 'Invalid Date') {
                formattedDate = originalDate;
            } else if (dateFormat.value) {
                formattedDate = format(date, dateFormat.value);
            }
        }
    }

    return formattedDate;
};

const setDate = (date, source) => {
    if (activeView.value !== 'month') return;

    let newDate = formatDate(new Date(date));
    if (source === 'picker') {
        dateFormatted.value = newDate;
    }
    if (validDate.value) {
        newDate = getDateValue();
    } else {
        newDate = undefined;
    }

    menu.value = false;
    emit('update:modelValue', newDate);
    emit('change');
};

const manualInput = () => {
    // input is being cleared out
    if (!dateFormatted.value) {
        dateValue.value = '';
        activeView.value = 'month';
        emit('update:modelValue', dateValue.value);
    }

    if (validDate.value) {
        dateValue.value = getDateValue();
        activeView.value = 'month';
        emit('update:modelValue', dateValue.value);
    }
    if (menu.value) {
        menu.value = false;
    }
};

const captureFieldInput = (e) => {
    // close picker if tabbing through date field
    if (e.keyCode === 9) {
        setTimeout(() => {
            menu.value = false;
            // timeout must be greater than what is used in the openMenu method
        }, 105);
    }
};

const getDateValue = () => {
    let returnValue = '';

    switch (dateFormatted.value.length) {
        case 10: {
            const date = parse(dateFormatted.value, dateFormat.value, new Date());
            try {
                returnValue = format(date, 'yyyy-MM-dd');
            } catch (e) {
                returnValue = dateFormatted.value;
            }
            break;
        }
        case 7: {
            let dateParts = dateFormatted.value.split(dateSeparator.value);
            let year = dateFormatted.value.indexOf(dateSeparator.value) === 4 ? dateParts[0] : dateParts[1];
            let month = dateFormatted.value.indexOf(dateSeparator.value) === 2 ? dateParts[0] : dateParts[1];

            returnValue = year + '-' + month;
            break;
        }
        case 6:
            returnValue = dateFormatted.value;
            break;
        case 4:
            returnValue = dateFormatted.value;
            break;
    }
    return returnValue;
};

const openMenu = () => {
    if (menu.value) return;

    setTimeout(() => {
        if (!menu.value) {
            menu.value = true;
        }
    }, 100);
    emit('focus');
};

const closeMenu = () => {
    emit('blur');
};

watch(
    () => props.modelValue,
    (val) => {
        dateValue.value = val;
        dateFormatted.value = formatDate(val);
    },
);

watch(
    () => menu.value,
    (val) => {
        if (val) activeView.value = props.dob ? 'years' : 'month';
    },
);
</script>

<template>
    <v-menu ref="menuRef" v-model="menu" :disabled="disabled || readonly" transition="scale-transition" :close-on-content-click="false" max-width="290px" min-width="290px">
        <template #activator="{ props }">
            <div class="d-flex">
                <font-awesome-icon v-if="icon" :icon="['far', icon]" class="mr-2 mt-1 align-self-start" />
                <v-text-field
                    :id="id"
                    v-bind="mergeProps($attrs, props)"
                    v-model="dateFormatted"
                    v-facade="dateMask"
                    :placeholder="dateFormat.toLowerCase()"
                    :readonly="readonly"
                    :disabled="disabled"
                    :clearable="clearable"
                    :hide-details="hideDetails"
                    :class="customClass"
                    :error-messages="!validDate && dateFormatted && dateFormatted.length ? ['Invalid date'] : errorMessages"
                    :data-cy="$attrs['data-cy'] + 'Input'"
                    @blur="closeMenu"
                    @focus="openMenu"
                    @update:model-value="manualInput"
                    @keydown="captureFieldInput"
                >
                    <template #label>
                        <span v-html="label"></span>
                    </template>
                </v-text-field>
            </div>
        </template>
        <v-card v-if="!readonly" style="width: 270px; height: 320px">
            <VueCal
                ref="pickerRef"
                v-model:active-view="activeView"
                class="vuecal--rounded-theme vuecal--green-theme"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                :disable-views="['day', 'week']"
                :selected-date="vueCalDate"
                :min-date="minDate"
                :max-date="maxDate"
                @cell-focus="setDate($event, 'picker')"
            />
        </v-card>
    </v-menu>
</template>
