<script>
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import { minLength, required } from '@vuelidate/validators';
import BnFormGoogleaddressText from '@/components/forms/BnFormGoogleaddressText.vue';
import provinces from 'provinces';
import { getGoogleTimeZoneInfo } from '@/common/api/googleTimeZone.api';
import { constants } from '@/fhirworks';
import { useVuelidate } from '@vuelidate/core';
import BnFooter from '@/components/BnFooter';

export default {
    name: 'BnContactAddress',
    components: { BnFormGoogleaddressText, BnToolbarBtn, BnFooter },
    props: {
        addressType: {
            type: String,
        },
        mode: {
            type: String,
            default: 'edit',
        },
        disablePreferredCheck: {
            type: Boolean,
            default: false,
        },
        resource: {
            type: Object,
            required: true,
        },
        address: {
            type: Object,
            required: true,
        },
        addressUseOptions: {
            type: Array,
            default: () => [
                { value: 'home', text: 'Home' },
                { value: 'work', text: 'Work' },
                { value: 'billing', text: 'Mailing' },
            ],
        },
        claimAddressUseOptions: {
            type: Array,
            default: () => [
                { value: 'claim', text: 'Claim' },
                { value: 'appeal', text: 'Appeal' },
            ],
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            workingAddress: null,
            addressOriginalValues: {},
            bnGoogleAddressAutoComplete: {},
            timeZone: undefined,
        };
    },

    computed: {
        usStates() {
            return provinces
                .filter((province) => province.country === 'US')
                .map((item) => {
                    return { value: item.short, title: item.name };
                })
                .sort((a, b) => (a.title > b.title ? 1 : -1));
        },
    },
    created() {
        this.addressOriginalValues = this.address.toJSON();
        this.workingAddress = this.address;
    },
    validations() {
        return {
            workingAddress: {
                line1: {
                    required,
                    minLength: minLength(5),
                },
                city: {
                    required,
                    minLength: minLength(2),
                },
                state: {
                    required,
                },
                postalCode: {
                    required,
                    minLength: minLength(5),
                },
            },
        };
    },
    methods: {
        googleAutoCompleteHandler: function (val) {
            // Only update the timezone if this is a home addreess and there isn't a TZ set
            if (this.workingAddress.use === constants.addressUseValues.home && !this.resource.timeZone) {
                let locationInfo = { lat: val.geometry.location.lat, lng: val.geometry.location.lng };
                this.timeZone = undefined;
                if (locationInfo) {
                    getGoogleTimeZoneInfo(locationInfo).then(
                        (res) => {
                            this.timeZone = res.data.timeZoneId;
                        },
                        () => {},
                    );
                }
            }

            this.workingAddress.line1 = val.line1;
            this.workingAddress.line2 = val.line2;
            this.workingAddress.city = val.city;
            this.workingAddress.state = val.state;
            this.workingAddress.postalCode = val.postalCode;
        },
        cancelWorkingAddress() {
            if (this.mode === 'edit') {
                // Because we are working with the actual object we must restore
                // the original value when form was called
                this.workingAddress.setPropertyValues(this.addressOriginalValues);
            }
            this.workingAddress = null;
            this.$emit('cancel');
        },
        async saveWorkingAddress() {
            this.v$.workingAddress.$touch();

            let valid = await this.v$.workingAddress.$validate();
            if (!valid) return;

            // Add mode so add to array of contact points so it can be processed below
            if (this.mode === 'add') {
                this.resource.address = this.workingAddress;
            }

            if (this.timeZone) {
                this.resource.timeZone = this.timeZone;
            }

            // Setting of preferred for contact point
            if (this.workingAddress.preferred) {
                if (Array.isArray(this.resource.address)) {
                    this.resource.address.forEach((address) => {
                        address.preferred = false;
                    });
                } else {
                    this.resource.address.preferred = false;
                }
                this.workingAddress.preferred = true;
            }
            this.$emit('save', this.workingAddress);
        },
        // toSentenceCase(str) {
        //     return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1);
        // },
    },
};
</script>

<template>
    <v-card flat class="mx-auto d-flex flex-column" width="400">
        <div class="flex-grow-0">
            <ion-header>
                <ion-toolbar>
                    <v-toolbar density="compact" flat>
                        <!-- <v-toolbar-title>{{ toSentenceCase(this.addressType) }} address</v-toolbar-title> -->
                        <!-- <v-toolbar-title v-if="addressType">{{ this.addressType }} Address</v-toolbar-title> -->
                        <v-toolbar-title v-if="addressType">{{ addressType === 'claim' ? 'Claim' : 'Appeal' }} address</v-toolbar-title>
                        <v-toolbar-title v-else>Address</v-toolbar-title>
                        <bn-toolbar-btn
                            type="text"
                            :label="mode === 'edit' ? 'Cancel' : 'Discard'"
                            color="secondary"
                            :icon="mode === 'edit' ? 'times' : 'trash'"
                            @click="cancelWorkingAddress"
                        ></bn-toolbar-btn>
                        <bn-toolbar-btn
                            :disabled="!!v$.workingAddress.$errors.length"
                            :label="mode === 'edit' ? 'Update' : 'Add'"
                            color="primary"
                            :icon="mode === 'edit' ? 'check' : 'plus'"
                            data-test="verifyBtn"
                            data-cy="saveAddressButton"
                            @click="saveWorkingAddress"
                        />
                    </v-toolbar>
                </ion-toolbar>
            </ion-header>
            <v-divider></v-divider>
        </div>

        <v-card-text>
            <v-radio-group v-if="workingAddress.use === 'claim' || workingAddress.use === 'appeal'" v-model="workingAddress.use" :disabled="true"> </v-radio-group>
            <v-radio-group v-else-if="!workingAddress.use === 'claim' || !workingAddress.use === 'appeal' || addressUseOptions.length" v-model="workingAddress.use" label="Use" class="mb-4">
                <v-radio v-for="(addressOption, index) in addressUseOptions" :key="'addressOption' + index" :label="addressOption.text" :value="addressOption.value"></v-radio>
            </v-radio-group>
            <!-- <v-radio-group v-if="workingAddress.use === 'claim' || workingAddress.use === 'appeal'" v-model="workingAddress.use" label="Use" :disabled="true">
                <v-radio v-for="(addressOption, index) in claimAddressUseOptions" :key="'addressOption' + index" :label="addressOption.text" :value="addressOption.value"></v-radio>
            </v-radio-group> -->
            <!-- <v-radio-group v-if="addressUseOptions.length && !workingAddress.use === 'claim' || !workingAddress.use === 'appeal'" v-model="workingAddress.use" label="Use" class="mb-4">
                <v-radio v-for="(addressOption, index) in addressUseOptions" :key="'addressOption' + index" :label="addressOption.text" :value="addressOption.value"></v-radio>
            </v-radio-group> -->

            <bn-form-googleaddress-text
                ref="addressLine"
                v-model="workingAddress.line1"
                data-test="addressLine1"
                :autofocus="!workingAddress.line1"
                v-bind="$bnVuelidateErrorExtractor(v$.workingAddress, 'line1', true)"
                @blur="v$.workingAddress.line1.$touch()"
                @place-changed="googleAutoCompleteHandler"
            />

            <v-text-field id="addressLine2" v-model="workingAddress.line2" class="mt-2" label="Line 2" data-test="addressLine2" />
            <v-text-field
                id="city"
                v-model="workingAddress.city"
                data-test="addressCity"
                label="City"
                v-bind="$bnVuelidateErrorExtractor(v$.workingAddress, 'city', true)"
                @blur="v$.workingAddress.city.$touch()"
            />
            <v-row>
                <v-col cols="12" sm="7">
                    <v-select
                        id="state2"
                        v-model="workingAddress.state"
                        data-test="addressState"
                        label="State"
                        :items="usStates"
                        v-bind="$bnVuelidateErrorExtractor(v$.workingAddress, 'state', true)"
                        @blur="v$.workingAddress.state.$touch()"
                    />
                </v-col>
                <v-col cols="12" sm="5">
                    <v-text-field
                        id="zip"
                        v-model="workingAddress.postalCode"
                        data-test="addressZip"
                        label="Zip"
                        v-bind="$bnVuelidateErrorExtractor(v$.workingAddress, 'postalCode', true)"
                        @blur="v$.workingAddress.postalCode.$touch()"
                    />
                </v-col>
            </v-row>
            <v-text-field id="county" v-model="workingAddress.district" data-test="addressCounty" label="County" />

            <div class="bn-section-header">Options</div>
            <v-checkbox v-model="workingAddress.preferred" :disabled="disablePreferredCheck" class="mt-0" label="Preferred address" data-cy="bnAddressPreferredCheckbox"></v-checkbox>
        </v-card-text>
        <!-- ios ample touch footer bar -->
        <bn-footer class="dialog-footer"></bn-footer>
    </v-card>
</template>

<style scoped></style>
