<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue';
import BnAvatar from '@/components/BnAvatar.vue';
import BnResourceIcon from '@/components/BnResourceIcon.vue';
import BnChip from '@/components/BnChip.vue';
import debounce from 'lodash/debounce';
import { usePatientAccess } from '@/composables/usePatientAccess';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import systemSearchApi from '@/common/api/systemSearch.api';
import { Capacitor } from '@capacitor/core';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { EOC_STATUS_MAP } from '@/common/config';
import { computedAsync } from '@vueuse/core';
const authStore = useAuthStore();
const notificationStore = useNotificationStore();

defineOptions({
    inheritAttrs: false,
});

const props = defineProps({
    modelValue: [Object, String],
    searchResources: {
        type: Array,
        default: () => ['patient', 'organization', 'practitioner'],
    },
    addResources: {
        type: Array,
        default: () => ['patient', 'person', 'organization', 'practitioner'],
    },
    label: {
        type: String,
        default: 'Search contacts',
    },
    noAdd: {
        type: Boolean,
        default: false,
    },
    enforceAccess: {
        type: Boolean,
        default: true,
    },
    defaultQuery: String,
    filter: Function,
    filterByBrand: Object,
    flat: Boolean,
    variant: {
        type: String,
        default: 'outlined',
    },
    density: String,
    autofocus: {
        type: Boolean,
        default: true,
    },
    hideDetails: {
        type: Boolean,
        default: true,
    },
    placeholder: String,
    emptyListText: {
        type: String,
        default: 'Search for a contact',
    },
    addSearchItemAttrs: {
        type: Function,
        default: null,
    },
    prependIcon: {
        type: String,
        default: '',
    },
    relationshipSearch: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['itemClicked', 'addNewClicked', 'blur']);

const isLoading = ref(false);
const showFooter = ref(false);
const contacts = ref([]);
const search = ref(null);
const searchTerm = ref(null);
const dataLoaded = ref(false);
const defaultQueryResults = ref([]);

// refs
const searchBox = ref(null);

// Watchers
watch(
    search,
    debounce(
        function (val) {
            // Items have already been loaded
            if (dataLoaded.value) {
                contacts.value = [];
                searchTerm.value = null;
                dataLoaded.value = false;
                return;
            }

            // require at least 2 chars before searching
            if (!val || val.length < 2) {
                contacts.value = [];
                searchTerm.value = null;
                showFooter.value = false;
                return;
            }

            isLoading.value = true;
            showFooter.value = !props.noAdd;

            // execute search query
            systemSearchApi.search(val, props.searchResources, props.filterByBrand, props.enforceAccess).then((contactsReturn) => {
                if (props.filter) {
                    contactsReturn = contactsReturn.filter(props.filter);
                }

                if (props.addSearchItemAttrs instanceof Function) props.addSearchItemAttrs(contactsReturn);

                contacts.value = contactsReturn;
                isLoading.value = false;
            });
        },
        200,
        { maxWait: 500 },
    ),
);

onMounted(() => {
    // Fix for ios searchBox autofocus
    setTimeout(() => {
        Capacitor.getPlatform() === 'ios' ? searchBox.value.focus() : null;
    }, 100);
});

// Computed
const computedContacts = computedAsync(async () => {
    let results = contacts.value?.length || search.value?.length > 2 ? contacts.value.slice(0, 30) : defaultQueryResults.value;
    results = props.filter ? results.filter(props.filter) : results;
    let patientIds = results.filter((result) => result.resourceType === 'Patient').map((result) => result.id);
    let practitionerIds = results.filter((result) => result.resourceType === 'Practitioner').map((result) => result.id);
    // Pull patient eoc and practitioner information at the same time
    let promises = [$httpFhirApi.get('EpisodeOfCare?patient=' + patientIds.join(',') + '&_sort=-date'), $httpFhirApi.get('User?practitioner=' + practitionerIds.join(','))];
    const [eocData, userData] = await Promise.all(promises);

    results.forEach((result) => {
        // Find a patient's most recent episode of care status and connect it to their profile information
        if (result.resourceType === 'Patient' && result.id) {
            const patientId = result.id;
            result.patientId = patientId;
            let data = eocData.data.find((item) => item.patient.id === patientId);
            if (data) {
                result.eocStatus = data.status;
            }
        }
        // Attach status to practitioners
        if (result.resourceType === 'Practitioner' && result.id) {
            const practitionerId = result.id;
            result.practitionerId = practitionerId;
            let data = userData.data.find((item) => item.practitioner.id === practitionerId);
            if (data) {
                result.active = data.active;
            }
        }
    });
    // Remove practitioners that aren't active from results
    results = results.filter((result) => {
        if (result.resourceType === 'Practitioner') {
            return result.active !== false;
        }
        return true;
    });

    return results;
});

// Methods
const focus = () => {
    dataLoaded.value = true;
    searchBox.value.focus();
};
const eocStatusChip = (eocStatus) => {
    return EOC_STATUS_MAP[eocStatus];
};

// execute defaultQuery and populate default contact list
const loadDefaultContacts = async () => {
    if (!search.value?.length && props.defaultQuery) {
        // only reload defaults if query is for 'recent' contacts or list is empty
        if (defaultQueryResults.value?.length && props.defaultQuery !== 'recent') {
            return;
        }

        systemSearchApi.search(props.defaultQuery, props.searchResources, props.filterByBrand, props.enforceAccess).then((defaultContacts) => {
            const headerMessage = props.defaultQuery === 'recent' ? 'Recently accessed contacts:' : 'Suggested';
            defaultQueryResults.value = defaultContacts.length ? [{ header: headerMessage }, ...defaultContacts] : [];
        });
        // force autocomplete to open so that results are shown when autofocusing
        searchBox.value.menu = true;
    }
};

const { hasAccessToContact } = usePatientAccess();

const selectContact = (item) => {
    searchTerm.value = '';
    if (!item) return;

    if (!search.value) {
        nextTick(() => {
            searchTerm.value = null;
            contacts.value = [];
        });
    }

    focus();
    nextTick(() => {
        search.value = null;
    });

    if (!hasAccessToContact(item) && !props.relationshipSearch) {
        notificationStore.add({
            message: 'You do not have access to this ' + authStore.clientVocab,
            color: 'warning',
        });
        return;
    }

    emit('itemClicked', item);
};

const addContact = (type) => {
    emit('addNewClicked', type);
    nextTick(() => {
        if (searchBox.value) {
            searchBox.value.isMenuActive = false;
        }
        searchTerm.value = null;
    });
};

const resetSearch = (item) => {
    if (!item) return;
    // force reset of defaultQuery results
    defaultQueryResults.value = [];
    emit('itemCleared', item);
};

const displayName = (item) => {
    return item.fullName || item.display || item.name;
};
</script>

<template>
    <v-autocomplete
        ref="searchBox"
        v-bind="$attrs"
        v-model="searchTerm"
        v-model:search="search"
        :data-cy="$attrs['data-cy']"
        data-qa="search-input"
        :density="density"
        :variant="variant"
        :flat="flat"
        :hide-details="hideDetails"
        :hide-selected="!!props.value"
        :items="computedContacts"
        :item-title="displayName"
        :label="label"
        :loading="isLoading"
        no-filter
        :placeholder="placeholder"
        :prepend-icon="prependIcon"
        return-object
        class="bg-white"
        @blur="$emit('blur')"
        @update:model-value="selectContact"
        @focus="loadDefaultContacts"
        @click:clear="resetSearch"
    >
        <template #no-data>
            <!-- the <slot> tag below is required for passing in custom content from parent elements -->
            <slot name="no-data">
                <v-list-item>
                    <v-list-item-title>
                        {{ emptyListText }}
                    </v-list-item-title>
                </v-list-item>
            </slot>
        </template>

        <template #item="{ item, props }">
            <v-list-subheader v-if="item.raw?.header">{{ item.raw.header }}</v-list-subheader>
            <v-list-item v-else v-bind="props" :title="null" :data-cy="$attrs['data-cy'] + 'Result'">
                <template #prepend>
                    <bn-avatar :resource="item.raw" :size="28" :alt="item.raw.fullName + ' photo'" class="mr-4 my-2" />
                </template>
                <!--                <div class="bn-contact-search-list-content" :data-cy="$attrs['data-cy'] + 'Result'" :class="{ usprivacy: getUSPrivacy(item) }">-->
                <v-list-item-title>{{ item.raw.fullName || item.raw.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.raw.birthDate">{{ $filters.formatDate(item.raw.birthDate) }}</v-list-item-subtitle>
                <span v-if="item.raw.resourceType === 'Patient' && item.raw.eocStatus !== undefined" :style="{ fontWeight: 'normal' }"
                    ><bn-chip native label size="small" v-bind="eocStatusChip(item.raw.eocStatus)"
                /></span>
                <v-list-item-subtitle v-if="item.raw.resourceType === 'Organization' && item.raw.workAddress">{{
                    $filters.singleLineAddress(item.raw.workAddress, { postalCode: false })
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.raw._group === 2 && item.raw.resourceType !== 'Organization'">{{
                    $filters.singleLineAddress(item.raw.homeAddress, { postalCode: false })
                }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.raw._group === 3 && item.raw.primaryEmail">{{ item.raw.primaryEmail.value }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.raw._group === 3 && item.raw.primaryPhone">
                    {{ item.raw.primaryPhone.value }}
                </v-list-item-subtitle>
                <!--                </div>-->
                <template #append>
                    <span v-if="item.raw.chip" class="align-self-center">
                        <bn-chip native size="x-small" :color="item.raw.chip.color" :icon="item.raw.chip.icon" :value="item.raw.chip.value" />
                    </span>
                    <span v-if="!hasAccessToContact(item.raw)">
                        <font-awesome-icon :icon="['far', 'ban']" size="lg" />
                    </span>
                </template>
            </v-list-item>
        </template>

        <template v-if="showFooter && authStore.hasPermission(permissions.ADD_CONTACTS)" #append-item>
            <v-divider class="mt-2"></v-divider>
            <div class="pa-2">
                Add <strong>{{ search }}</strong> as
            </div>

            <v-list-item v-if="addResources.includes('patient')" :data-cy="$attrs['data-cy'] + 'AddPatient'" @click="addContact({ type: 'patient', value: search })">
                <template #prepend>
                    <bn-resource-icon resource="Patient"></bn-resource-icon>
                </template>
                <v-list-item-title>{{ authStore.clientVocab }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="addResources.includes('person')" :data-cy="$attrs['data-cy'] + 'AddPerson'" @click="addContact({ type: 'contact', value: search })">
                <template #prepend>
                    <bn-resource-icon resource="Person"></bn-resource-icon>
                </template>
                <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="addResources.includes('practitioner')" :data-cy="$attrs['data-cy'] + 'AddPractitioner'" @click="addContact({ type: 'practitioner', value: search })">
                <template #prepend>
                    <bn-resource-icon resource="Practitioner"></bn-resource-icon>
                </template>
                <v-list-item-title>Professional</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="addResources.includes('organization')" :data-cy="$attrs['data-cy'] + 'AddOrganization'" @click="addContact({ type: 'organization', value: search })">
                <template #prepend>
                    <bn-resource-icon resource="Organization"></bn-resource-icon>
                </template>
                <v-list-item-title>Organization</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="addResources.includes('user')" :data-cy="$attrs['data-cy'] + 'AddUser'" @click="addContact({ type: 'user', value: search })">
                <template #prepend>
                    <bn-resource-icon resource="User"></bn-resource-icon>
                </template>
                <v-list-item-title>User</v-list-item-title>
            </v-list-item>
        </template>
    </v-autocomplete>
</template>

<style lang="scss" scoped>
.v-autocomplete__content {
    min-height: 600px !important;
    min-width: 300px !important;
}

.bn-contact-search-list-content {
    max-width: 300px;
    user-select: none;
}
</style>
